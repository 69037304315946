'use strict';

import * as League from './pub/league';

const menu = (function($) {
  let _id = '',

    show = function($sub: JQuery) {
      $sub.prev('a.' + _id + 'link').addClass(_id + 'link_hover');
      $sub.delay(100).show();
    },

    hide = function($sub: JQuery) {
      $sub.prev('a.' + _id + 'link').removeClass(_id + 'link_hover');
      $sub.delay(200).hide();
    };

  return {
    init: function(id: string) {
      _id = id;
      const is_touch_device = 'ontouchstart' in window || navigator.msMaxTouchPoints;

      if (is_touch_device) {
        $('#' + _id + ' a.' + _id + 'link').click(function (e) {
          const $sub = $(this).next();
          if ($sub.length > 0 && $sub.is(':hidden')) {
            show($sub);
            e.preventDefault();
          }
        });
      }

      $('#' + _id + ' li').each(function() {
        var $li = $(this);
        if ($li.children().length > 1) {
          $li.hover(
            function () {
              if (!is_touch_device) {
                show($(this).children('a').next());
              }
            },
            function () {
              hide($(this).children('a').next());
            });
        }
      });
    }
  };
}(jQuery));



function init(): void {
  menu.init('headmenu');
  menu.init('bcnav');
}

function initPage(): void {
  let route: string = $('body').data('route');
  console.log(`Route: '${route}'`);

  switch (route) {
    case 'pub_league_id_standings':
      League.entry_standings();
      break;
    case 'pub_league_year_leaguegroup_overall_standings':
      League.overall_tournament_standings();
      break;
    case 'pub_league_year_standings':
      League.year_standings();
      break;
    default:
      console.log(`No code for route '${route}'`);
      break;
  }
}

$(function () {
  init();
  initPage();
});