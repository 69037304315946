'use strict';

export function minitable(): void {
  let _doNotHideMiniTable = false,
    _miniTabelleVisible = '';

  function toggleMiniTable(imgid: string) {
    const id = imgid.substring(0, imgid.indexOf('x')),
      miniTab = $('#tab-' + id);

    $('div.minitabelle').hide();

    if (_miniTabelleVisible == id) {
      _miniTabelleVisible = '';
    } else {
      _miniTabelleVisible = id;
      const tr = $('img[id^="' + id + '"]').parents('tr'),
        td = tr.find('td:nth-child(2)');
      miniTab.show();
      miniTab.offset({top: tr.offset()!.top + tr.height()!, left: td.offset()!.left - 2});
    }
  }

  $('img.showMiniTabelle').on('click', function () {
    _doNotHideMiniTable = true;
    toggleMiniTable(this.id);
  });

  $('div.minitabelle').on('click', function () {
    _doNotHideMiniTable = true;
  });

  $(document).on('click', function () {
    if (_doNotHideMiniTable) {
      _doNotHideMiniTable = false;
    } else {
      _miniTabelleVisible = '';
      $('div.minitabelle').hide();
    }
  });
}