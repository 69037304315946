'use strict';

import {minitable} from "./minitable";

export function entry_standings(): void {
  minitable();
}

export function overall_tournament_standings(): void {
  minitable();
}

export function year_standings(): void {
  minitable();
}